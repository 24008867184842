import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import Grow from '@mui/material/Grow';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import { currencyFormat } from '../../app/util/util';
import { addBasketItemAsync } from '../basket/basketSlice';
import { fetchAllProductsAsync, productAllSelectors } from '../home/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import { useEffect } from 'react';
import LoadingComponent from '../../app/layout/LoadingComponent';


export default function HomePage() {
    var settings = {       
        autoplay: true,
        pauseOnHover:false,
        speed: 450,
        autoplaySpeed: 1900,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
                autoplay: true,
                speed: 500,
                autoplaySpeed: 1900,
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: false,
                // initialSlide:2,
                // slidesPerRow: 2,
                cssEase: "linear"
            }
          },
          {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            }
          },
          {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            }
          }
        ]
      };

      const {status} = useAppSelector(state => state.basket);
    
      const products = useAppSelector(productAllSelectors.selectAll);
    
      const {productsAllLoaded} = useAppSelector(state => state.home);
    
      const dispatch = useAppDispatch();

  useEffect(() => {
    if (!productsAllLoaded) dispatch(fetchAllProductsAsync());    
  }, [productsAllLoaded, dispatch])

//   useEffect(() => {
//     if (!filtersLoaded) dispatch(fetchFilters());
//   }, [dispatch, filtersLoaded])

  if (!productsAllLoaded) return <LoadingComponent message='Loading products...' />
      

    return (

        <>
            <Slider {...settings}>
            {[...Array(11)].map((e, i) => {
                        return <div><img src={`/images/hero${i}.jpeg`} alt="hero" style={{ display: 'block', width: '100%', maxHeight: 550 }} /></div>
                })}
                {/* <div>
                    <img src="/images/hero2.jpeg" alt="hero" style={{ display: 'block', width: '100%', maxHeight: 500 }} />
                </div> */}
            </Slider>
            <Box display='flex' justifyContent='center' sx={{ p: 4 }} >
            <Grow
                timeout={1000}
                style={{ transformOrigin: '0 0 0' }}>
                    <img src="/LogoLight.png" alt="Logo AccuFinTech" width='50px' height='50px' style={{margin:'5px'}}/>
            </Grow>
            {/* <Zoom in={true} style={{ transitionDelay: '1000ms' }}>
                    
                    </Zoom>   */}
                <Typography variant='h5'>You are in the right place to Accounting and Enterprise Solutions</Typography>
            </Box>

            <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {products.map(product => (
                <Grid item xs={12} sm={4} md={4} sx={{mb: 2}} key={product.id}>
                <Card sx={{border:0, borderRadius: 5}} elevation={10}>
                    <CardHeader
                        avatar={
                            <Avatar sx={{bgcolor:'grey.500'}}>
                                {product.name.charAt(0).toUpperCase()}
                            </Avatar>
                        }
                        title={product.name}
                        titleTypographyProps={{
                            sx: {fontWeight:'bold'}
                        }}
                    />
                    <CardMedia
                        sx={{ height: 140, backgroundSize:'contain', bgcolor:'grey.100' }}
                        image={product.pictureUrl}
                        title={product.name}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5">
                            {currencyFormat(product.price)}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {product.brand} / {product.type}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <LoadingButton 
                            loading={status.includes('pendingAddItem' + product.id)} 
                            onClick={() => dispatch(addBasketItemAsync({productId: product.id}))} 
                            size="small">Add to cart</LoadingButton>
                        <Button component={Link} to={`/catalog/${product.id}`} size="small">View</Button>
                    </CardActions>
                </Card>
                </Grid>                
                ))}
            </Grid>
        </>
    )
}