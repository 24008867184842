// import { Button } from "@mui/material";
import { Box, Paper } from "@mui/material";
import ProductList from "./ProductList";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { fetchFilters, fetchProductsAsync, productSelectors, setPageNumber, setProductParams } from "./catalogSlice";
import { Grid } from '@mui/material';
// import ProductSearch from "./ProductSearch";
import RadioButtonGroup from "../../app/components/RadioButtonGroup";
import CheckBoxButtons from "../../app/components/CheckBoxButtons";
import AppPagination from "../../app/components/AppPagination";
import LoadingComponent from "../../app/layout/LoadingComponent";

const sortOptions = [
  {value: 'name', label: 'Alphabetical'},
  {value: 'priceDesc', label: 'Price - High to low'},
  {value: 'price', label: 'Price - Low to high'},
]

const Catalog = () => {

  const products = useAppSelector(productSelectors.selectAll);
  const {productsLoaded, filtersLoaded, brands, types , productParams, metaData} = useAppSelector(state => state.catalog);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!productsLoaded) dispatch(fetchProductsAsync());    
  }, [productsLoaded, dispatch])

  useEffect(() => {
    if (!filtersLoaded) dispatch(fetchFilters());
  }, [dispatch, filtersLoaded])

  if (!filtersLoaded && !productsLoaded) return <LoadingComponent message='Loading products...' />
  
  return (
    <Box sx={{ my: 2, flexGrow: 4 }}>
      <Grid container columnSpacing={4}>

        <Grid item xs={12} md={3}>

          {/* Searching */}
          {/* <Paper sx={{ mb: 2}}>
            <ProductSearch/>
          </Paper> */}
          
          {/* Categories or Types */}
          <Paper sx={{mb: 2, p:2}}>
            <CheckBoxButtons
              items={types}
              checked={productParams.types}
              onChange={(items: string[]) => dispatch(setProductParams({types: items}))} 
            />
          </Paper>

          {/* Sorting Ascending or descending */}
          <Paper sx={{mb: 2, p:2}}>
            <RadioButtonGroup 
              selectedValue={productParams.orderBy}
              options={sortOptions}
              onChange={(e) => dispatch(setProductParams({orderBy: e.target.value}))}
            />
          </Paper>

          {/* Labels of services */}
          <Paper sx={{mb: 2, p:2}}>
            <CheckBoxButtons
              items={brands}
              checked={productParams.brands}
              onChange={(items: string[]) => dispatch(setProductParams({brands: items}))} 
            />
          </Paper>

          

        </Grid>

        <Grid item xs={12} md={9}>
          <ProductList products={products}/>
        </Grid>
        
        {/* ----------------- PAGINATION -------------------- */}

        <Grid item xs={12} md={3} />

        <Grid item xs={12} md={9} sx={{mb:2}}>
                {metaData &&
                <AppPagination 
                    metaData={metaData}
                    onPageChange={(page: number) => dispatch(setPageNumber({pageNumber: page}))}
                />}
        </Grid>

        {/* ----------------- END PAGINATION -------------------- */}

      </Grid>
    </Box>
  )
};

export default Catalog;



// useEffect(() => { 
//   fetch('http://localhost:5009/api/products')
//     .then(response => response.json()) 
//     .then(data => setProducts(data));
//   }, [])
  
// useEffect(() =>{
//   axios.get('http://localhost:5009/api/products')
//   .then(response => setProducts(response.data))
//   .catch(error => console.log(error))
//   .finally(() => setLoading(false));
// }, [])

// useEffect(() =>{
//   agent.Catalog.list()
//     .then(products => setProducts(products))
//     .catch (error => console.log(error))
//     .finally(() => setLoading(false));
// }, [])

// export default function Catalog({products, addProduct}: Props) {
//     return (
//         <>
//             <h1>Catalog</h1>
//                 <List>
//                     {products.map(product => (
//                     <ListItem key={product.id}>
//                         <ListItemAvatar>
//                             <Avatar src={product.pictureUrl} />
//                         </ListItemAvatar>
//                         <ListItemText>
//                             {product.name} - {product.price}
//                         </ListItemText>
//                     </ListItem>
//                     ))}
//                 </List>
//                 <Button variant="contained" color="primary" onClick={addProduct}>Add product</Button>
//         </>
    
//     )    
// }

// function addProduct(){
//     setProducts(prevState => [...prevState, 
//       {
//         id: prevState.length + 101,
//         name: 'product' + (prevState.length + 1), 
//         price: (prevState.length * 100) + 100,
//         brand: 'some brand name',
//         description: 'description detailes',
//         pictureUrl: 'http://picsum.photos/200',
//       }])
//   }
// {/* <Button variant="contained"  sx={{mt:2, mb:2, bgcolor:'grey.700'}} onClick={addProduct}>Add product</Button> */}