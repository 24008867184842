import { Typography } from '@mui/material';
import React from 'react';

const CheckoutPage = () => {
    return (
        <Typography variant='h3'>
            Only logged in users should be able to see this!
        </Typography>
    );
};

export default CheckoutPage;